import React, { useContext, useEffect, useState} from "react";
import Page from "components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import PageWrapper from "../../components/PageContent/PageWrapper";
import PageTitle from "../../components/PageContent/PageTitle";
import {useNavigate} from "react-router-dom";
import {updateCompanyVehicle, deleteVehicleImage, getVehicle} from "../../services/userService";
import {AuthContext} from "../../context/AuthContext";
import {Auth0ContextType} from "../../@types/auth";
import {
    CarDetail, CarDetailApiResponse,
} from "../../models/car";
import CarForm from "../../features/CarForm";
import {useParams} from "react-router";
import SmallLoading from "../../components/Loadings/SmallLoading";
import snackBarUtils from "../../utils/SnackBarUtils";

export default function EditCarPage() {
    const {t} = useLocales();
    const {user} = useContext(AuthContext) as Auth0ContextType;
    const [vehicle, setVehicle] = useState<CarDetailApiResponse>();
    const {carId} = useParams();
    const navigate = useNavigate();

    const getCar = () =>{
        getVehicle(user?.company.id!, carId!).then((res)=>{
            setVehicle(undefined);
            setVehicle(res)
        }).catch((err)=>{

        })
    }

    const handleSubmit = (car: CarDetail) => {
        const {images ,...toUpdate} = car

        updateCompanyVehicle(user?.company.id!, carId!, toUpdate).then((res)=>{
            snackBarUtils.success("SUCCESSFULD")
            getCar();
        }).catch((err)=>{

        })
    }
    const removeimage = (id: string) => {
        deleteVehicleImage(user?.company.id!, carId!, id).then((res)=>{
            getCar();
        }).catch((err)=>{

        })
    }

    useEffect(() => {
        getCar();
    }, []);


    return (
        <Page title={"Edit car " + vehicle?.make + vehicle?.model }>
            <PageWrapper>
                {!vehicle && <SmallLoading />}
                {vehicle &&
                    <>
                    <PageTitle title={"Edit vehicle"}/>
                        <CarForm vehicle={vehicle} locations={user?.company.locations!} handleSubmit={handleSubmit} handleRemoveImage={removeimage}/>
                    </>
                }
            </PageWrapper>
        </Page>
    );
}


