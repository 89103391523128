import { useField } from "formik";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import useLocales from "../../hooks/useLocales";

interface Probs {
    placeholder?: string;
    name: string,
    label? : string
    values?: string[],
    defaultValue?: number,
    required? : boolean,
    valueText?:string,
}

export default function MySelectInput(probs: Probs){
    const [field, meta] = useField(probs.name)
    const { translate } = useLocales();
    return (
        <FormControl fullWidth error={meta.touched && !!meta.error} sx={{'.MuiFormControlLabel-root': { margin: 0 }}}>
          <FormControlLabel control={<Select
            variant="outlined"
            id={probs.name}
            defaultValue={probs.defaultValue ?? null}
            label={probs.label}
            style={{background: '#fff', marginTop: 5, width: '100%'}}
            {...field} {...probs}>
            {probs.values && (
              probs.values.map((value, index)=>(
                <MenuItem key={index} value={index}>{translate(value)}</MenuItem>
              ))
            )}
          </Select>} label={probs.label} />


        {meta.touched && meta.error ? (
            <Alert severity="error">{meta.error}</Alert>
        ): null}

        </FormControl>
    )
}
