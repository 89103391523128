import useLocales from "../hooks/useLocales";
import { LocaleObject } from "yup/lib/locale";

export default function YupLocales(){
  const { translate } = useLocales();
  const locales :  LocaleObject = {
    mixed: {
      default: translate('yup.field_invalid'),
      required: ({ path }) => translate('yup.field_required', { path: translate("general."+ path) }),
      oneOf: ({ path, values, }) => translate('yup.mixed_oneOf', { values, path: translate('general.'+path) }),
      notOneOf: ({ path, values, }) => translate('yup.mixed_notOneOf', { values,  path: translate('general.'+path) }),
      notType: ({ path,  }) => translate('yup.mixed_notType', { path: translate('general.'+path) }),
      defined: ({ path }) => translate('yup.mixed_defined', { path: translate('general.'+path) }),
    },
    number: {
      min: ({ min, path }) => translate('yup.number_min', { min, path: translate("general."+ path)  }),
      max: ({ max,path }) => translate('yup.number_max', { max, path: translate("general."+ path)  }),
      lessThan: ({ path, less }) => translate('yup.number_lessThan', { path: translate('general.'+path), less }),
      moreThan:({ path , more }) => translate('yup.number_moreThan', { more, path: translate('general.'+path) }),
      positive:({ path }) => translate('yup.number_positive', { path: translate('general.'+path) }),
      negative:({ path, less }) => translate('yup.number_negative', { path: translate('general.'+path), less }),
      integer:({ path }) => translate('yup.number_integer', { path: translate('general.'+path), }),
    },
    string: {
      length: ({ path }) => translate('yup.string_length', { path: translate('general.'+path) }),
      min: ({ min, path }) => translate('yup.string_min', { min, path: translate("general."+ path)  }),
      max: ({ max,path }) => translate('yup.string_max', { max, path: translate("general."+ path)  }),
      email: ({ path }) => translate('yup.string_email', { path: translate("general."+ path)  }),
      matches: ({ path }) => translate('string_matches', { path: translate('general.'+path) }),
      url: ({ path }) => translate('yup.string_url', { path: translate('general.'+path) }),
      uuid: ({ path }) => translate('yup.string_uuid', { path: translate('general.'+path) }),
      trim: ({ path }) => translate('yup.string_trim', { path: translate('general.'+path) }),
      lowercase: ({ path }) => translate('yup.string_lowercase', { path: translate('general.'+path) }),
      uppercase: ({ path }) => translate('yup.string_uppercase', { path: translate('general.'+path) }),
    },
    date: {
      min:  ({ min, path }) => translate('yup.date_min', { min, path: translate("general."+ path)  }),
      max:  ({ max, path }) => translate('yup.date_max', { max, path: translate("general."+ path)  }),
    },
    array: {
      length: ({ path,length }) => translate('yup.array_length', { length, path: translate('general.'+path) }),
      min: ({ path,min }) => translate('yup.array_min', { min, path: translate('general.'+path) }),
      max: ({ path,max }) => translate('yup.array_max', { max, path: translate('general.'+path) }),
    },
    boolean: {
      isValue: ({ path, value }) =>translate('boolean_isValue', { value, path: translate("general."+ path)  }),
    }
  }

  return {
    locales
  }
}
