import Page from 'components/PageContent/Page';
import useLocales from "../hooks/useLocales";
import PageTitle from "../components/PageContent/PageTitle";
import React from "react";

export default function Dashboard() {
  const { t } = useLocales()
    return (

            <PageTitle title={t("general.dashboard")} />

    )
}
