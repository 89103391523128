import { Paper } from '@mui/material'
import React from 'react'
import { styled } from "@mui/material/styles";

interface Props {
    children: React.ReactNode
    bgcolor?: 'gray' | 'blue' | 'outlined'
    border?: boolean
    paper?: boolean
}

const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: '10px',
  "& .showPaper": {
    padding: "20px",
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  '& .border': {
    border: '1px solid rgba(23,29,75,.2)',
    padding: '20px',
    margin: '0px 0px 20px',
    borderRadius: '5px',

  },

  '& .noBorder': {
    padding: '20px',
    //margin: '20px 0px',
    borderRadius: '5px',
  },

  '& .gray': {
    background: '#E5E5E5',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  '& .blue': {
    background: '#f1f5f8',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
    '& .outlined': {
        border: `2px solid ${theme.palette.primary.main}`
    }
}));

const ListItemWrapper: React.FC<Props> = ({ children, bgcolor, paper, border }) => (
      <Wrapper >
           <div className={`${bgcolor} ${border == true ? 'border' : 'noBorder'}` }>
              {paper == true ?
                 <Paper className="showPaper">
                  {children}
                 </Paper>
              :
                children
              }
        </div>
      </Wrapper>
  )

export default ListItemWrapper
