import React from "react";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import BtnLink from "components/Buttons/BtnLink";
import useLocales from "../hooks/useLocales";



export default function Page404(){
    const { t } = useLocales()
    return (

          <Box style={{ display: 'flex', justifyContent: 'center',textAlign: 'center', flexDirection: 'column', height: '100vh',   alignItems: 'center', background:'#FBFBFB'   }}>
            <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center',flexDirection: 'column',maxWidth: '1200px', margin: 'auto', padding: '0 20px' }}>
              <img src={'/assets/404-bg.png'} style={{ maxWidth:'100%' }} alt="404-bg"/>
              <Typography variant="h1" component="h1" sx={{ fontWeight: 600, color: '#171D4B', fontSize:  { lg:150, md:80 } }}>{t('general.weAreSorry')}</Typography>
              <Typography variant="h5" sx={{ marginTop: '15px', color: '#171D4B', }} component="p">{t('general.404')}</Typography>

              <Stack justifyContent={'center'} direction="column" alignItems={'center'} alignContent="center" sx={{ marginTop: '10px' ,'.MuiButtonBase-root':{ marginBottom: '10px' } }}>

              </Stack>
            </Box>
          </Box>
    )
}
