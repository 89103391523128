import React from "react";
import { useField } from "formik";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';

interface Probs {
    placeholder?: string;
    name: string,
    label? : string
}

export default function MyTextAreaInput(probs: Probs){
    const [field, meta] = useField(probs.name)
    return (
        <FormControl error={meta.touched && !!meta.error} fullWidth>
        <label style={{ color: '#3d405b' }} />
        <TextField
            id={probs.name}
            label={probs.label}
            multiline
            rows={10}
            sx={{ width: '100%' }}
            {...field} {...probs}/>
        {meta.touched && meta.error ? (
            <Alert severity="error">{meta.error}</Alert>
        ): null}
        </FormControl>
    )
}
