import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'


i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'da',
  detection: {
    order: ['localStorage'],
    caches: ['localStorage']
  },
  resources: {
    en: {
      translations: {
        'yup': require('./en/yup.json'),
        "profile": require('./en/profile.json'),
        "general": require('./en/general.json'),
        "menu": require('./en/menu.json'),
      }
    },
    da: {
      translations: {
        'yup': require('./da/yup.json'),
        "profile": require('./da/profile.json'),
        "general": require('./da/general.json'),
        "menu": require('./da/menu.json'),
      }
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['da-DK','en'];

export default i18n;
