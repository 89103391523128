import React, {FunctionComponent, useContext, useState} from 'react';
import {ErrorMessage, Form, Formik} from "formik";
import MyTextInput from 'components/Forms/MyTextInput';
import MyPasswordField from 'components/Forms/MyPasswordField';
import BtnLoading from 'components/Buttons/BtnLoading';
import {TextField, Typography} from '@mui/material';
import Alert from "@mui/material/Alert";
import useLocales from 'hooks/useLocales';
import * as Yup from 'yup';
import DialogComponent from 'components/DialogComponent';
import DialogFooter from 'components/DialogComponent/DialogFooter';
import BtnAction from 'components/Buttons/BtnAction';
import {AuthContext} from "../context/AuthContext";
import {Auth0ContextType} from "../@types/auth";
import {useNavigate} from "react-router-dom";

interface OwnProps {}

type Props = OwnProps;

const LoginForm: FunctionComponent<Props> = (props) => {
    const { t } = useLocales()
    const {login} = useContext(AuthContext) as Auth0ContextType;
    const navigate = useNavigate();
    const [dialogState, setDialogState] = useState(false)
    const [resetPasswordState, setResetPasswordState] = useState('')
    const [isSubmittingReset, setIsSubmittingReset] = useState(false)

    const loginValidationSchema = Yup.object().shape({
        email: Yup.string().required(),
        password: Yup.string().required()
    });


    return(
        <>
            <Formik  initialValues={ { email: '', password:'', error: null }} validationSchema={loginValidationSchema}
                     onSubmit={(values, { setErrors, setSubmitting })=> {
                         login({email: values.email, password: values.password}).then((res)=>{
                          window.location.replace('/')
                         }).catch((err)=>{
                             if(err.validation){
                                 setErrors(err.validation);
                             }
                         }).finally(() => setSubmitting(false));
                     }
                     }>
                {({ isSubmitting, errors })=>(
                    <Form autoComplete="off">
                        <MyTextInput name="email" placeholder={t('general.enterUserNameOrEmail')}/>
                        <MyPasswordField name="password" placeholder={t('general.password')}/>
                        <ErrorMessage name="error" render={()=>  <Alert severity="error">{errors.error}</Alert> }/>
                        <BtnLoading
                            fullWidth
                            label="Login"
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            isSubmitting={isSubmitting}
                        />
                        <Typography
                            variant="body1"
                            sx={{ marginTop: '30px',
                                color: '#1976d2',
                                transition: 'all .3s ease-in-out',
                                '&:hover':{
                                    cursor: 'pointer',
                                    color: '#2ED3C6' } }}
                            onClick={()=>setDialogState(!dialogState)}
                        >{t('general.forgotPassword')}
                        </Typography>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default LoginForm;
