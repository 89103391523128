
import { Box, Stack, Link, Alert, Container, Typography } from '@mui/material';
// routes

// components
import Page from '../components/PageContent/Page';
import Logo from '../components/Logo';
import LoginForm from "../features/LoginForm";
import useLocales from "../hooks/useLocales";
import { RootStyle, ContentStyle, HeaderStyle } from "./Containers";

// ----------------------------------------------------------------------



export default function LoginPage() {
  const { translate } = useLocales();

  return (
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>
          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {translate('general.signIn')}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{translate('general.enterDetails')}</Typography>
                </Box>
              </Stack>
              <Alert severity="info" sx={{ mb: 3 }}>{translate('general.receivedInEmail')}</Alert>
              <LoginForm />

                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    {translate('general.dontHaveAnAccount')} {' '}
                    <Link variant="subtitle2" href={'https://www.autobout.com'}>
                      {translate('general.getStarted')}
                    </Link>
                  </Typography>

            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
  );
}
