import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { NAVBAR } from '../../config';

import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';


const MainStyle = styled('main', {
})(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 }, }}>
      <DashboardHeader isCollapse={false} onOpenSidebar={() => setOpen(true)} />
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
