import {Box, Typography} from '@mui/material';
import React, {FunctionComponent} from 'react';

interface Probs{
    title: string
}

const PageTitle: FunctionComponent<Probs> = ({  title})  => {
  return(
      <Box sx={{ marginTop: '120px', textAlign: 'center' }}>
        <Typography variant="h1">{title}</Typography>
      </Box>
  )
}

export default PageTitle;
