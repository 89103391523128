import React, {FunctionComponent, useEffect} from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import NotistackProvider from "./components/NotistackProvider";
import {ProgressBarStyle} from "./components/ProgressBar";
import {BrowserRouter} from "react-router-dom";
import Routes from "./routes";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {Provider} from "react-redux";
import useLocales from "./hooks/useLocales";
import {da, enUS} from "date-fns/locale";
import {setLocale} from "yup";
import YupLocales from "./@types/yupLocales";
import {AuthProvider} from "./context/AuthContext";

interface OwnProps {
}

type Props = OwnProps;

const localeMap = {
    da: da,
    en: enUS
};
const App: FunctionComponent<Props> = (props) => {
    const {currentLang} = useLocales();
    const {locales} = YupLocales();

    const [localize, setLocalize] = React.useState<keyof typeof localeMap>('da');
    const selectLocale = (newLocale: any) => {
        setLocalize(newLocale);
    };

    const setLo = () => {
        setLocale(locales)
    }


    useEffect(() => {
        selectLocale(currentLang.value)
    }, [currentLang]);

    useEffect(() => {
        setLo()
    }, []);


    return (
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[localize]}>
                <AuthProvider>
                    <NotistackProvider>
                        <ProgressBarStyle/>
                        <Routes/>
                    </NotistackProvider>
                </AuthProvider>
            </LocalizationProvider>
        </BrowserRouter>
    );
};

export default App;
