import { LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function SmallLoading() {
  return (
    <Box sx={{ width: '100%', marginTop: '30px' }}>
            <LinearProgress color="inherit" sx={{ color:'text.main' }} />
    </Box>
  )
}
