export * from './type';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';

export function isExternalLink(path: string) {
  return path.includes('http');
}

export function getActive(path: string, pathname: string) {
  return path ? path == pathname : false;
}
