import { useState, ReactNode } from "react";
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from "../hooks/useAuth";
import RedirectToLogin from "../features/RedirectToLogin";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};


export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const navigate = useNavigate();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <RedirectToLogin />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
