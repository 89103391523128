// @mui
import {enUS, daDK} from '@mui/material/locale';

// GENERAL CONST
// ----------------------------------------------------------------------


export const GMAP_API = process.env.REACT_APP_GMAP_API || '';


// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 10,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 320,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 60,
    DASHBOARD_ITEM_SUB_HEIGHT: 52,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
        label: 'Dansk',
        value: 'da',
        systemValue: daDK,
        icon: '/assets/icons/flags/ic_flag_da.svg',
    }
];
export interface Country{
    name: string;
        code: string;
    currency: string;
    dial_code: string;

}
export const Countries:  Country[] = [
    {
        name: "Danmark",
        code: "DK",
        currency: "DKK",
        dial_code: "+45"
    },
    {
        name: "Sweden",
        code: "SWE",
        currency: "SEK",
        dial_code: "+46"
    }
]

export const defaultLang = allLangs[0]; // English
