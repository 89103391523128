import {useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import {AuthContext} from "../../../context/AuthContext";
import {Auth0ContextType} from "../../../@types/auth";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.shorter,
        }),
    },
}));

// ----------------------------------------------------------------------

type Props = {
    isOpenSidebar: boolean;
    onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
    const theme = useTheme();

    const { pathname } = useLocation();
    const {user} = useContext(AuthContext) as Auth0ContextType;

    const isDesktop = useResponsive('up', 'lg');


    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Logo  sx={{width: '200px'}}/>
                </Stack>

                <NavbarAccount isCollapse={false} />
            </Stack>

            {user && user.role == "admin" && <NavSectionVertical navConfig={navConfig.Admin} isCollapse={false} />}
            {user && user.role == "user" && <NavSectionVertical navConfig={navConfig.User} isCollapse={false} />}

            <Box sx={{ flexGrow: 1 }} />
            <NavbarDocs />
        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg:  NAVBAR.DASHBOARD_WIDTH,
                },
                height: 1,
            }}
        >
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
                >
                    {renderContent}
                </Drawer>
            )}
            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: NAVBAR.DASHBOARD_WIDTH,
                            borderRightStyle: 'solid',
                            bgcolor: 'background.default',
                            transition: (theme) =>
                                theme.transitions.create('width', {
                                    duration: theme.transitions.duration.standard,
                                }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

        </RootStyle>
    );
}
