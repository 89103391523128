import { createContext, ReactNode, useEffect, useReducer } from 'react';
import {ActionMap, AuthState, Auth0ContextType, User} from '../@types/auth';
import {apiLogin, getUser} from "../services/userService";
import {UserData} from "../models/user";

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

enum Types {
    init = 'INITIALIZE',
    login = 'LOGIN',
    logout = 'LOGOUT',
}

type Auth0AuthPayload = {
    [Types.init]: {
        isAuthenticated: boolean;
        user: User | null;
    };
    [Types.login]: {
        user: User | null;
    };
    [Types.logout]: undefined;
};

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>];

const reducer = (state: AuthState, action: Auth0Actions) => {
    if (action.type === Types.init) {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    }
    if (action.type === Types.login) {
        const { user } = action.payload;
        return { ...state, isAuthenticated: true, user };
    }
    if (action.type === Types.logout) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }
    return state;
};

const AuthContext = createContext<Auth0ContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);


    useEffect(() => {
        console.log(process.env.REACT_APP_API_URL)
        const initialize = async () => {
            try {
                var token = window.localStorage.getItem('token');

                if(token){
                    getUser().then((res)=>{
                        dispatch({
                            type: Types.init,
                            payload: { isAuthenticated: true, user: res },
                        });
                    }).catch((err)=>{
                        dispatch({
                            type: Types.init,
                            payload: { isAuthenticated: false, user: null },
                        });
                    })
                }else{
                    dispatch({
                        type: Types.init,
                        payload: { isAuthenticated: false, user: null },
                    });
                }

            } catch (err) {
                console.error("asdasd",err);
                dispatch({
                    type: Types.init,
                    payload: { isAuthenticated: false, user: null },
                });
            }
        };

        initialize();
    }, []);

    const login = async (data: {email:string, password:string}): Promise<UserData> => {
        return apiLogin(data).then(res=> {
            window.localStorage.setItem('token', res.token)
            dispatch({
                type: Types.init,
                payload: { isAuthenticated: true, user: res.user },
            })
            return res;
        }).catch((error)=> {
                throw error
        });
    };

    const logout = async () => {
        window.localStorage.removeItem('token')
        dispatch({type: Types.logout})
    };

    return (
        <AuthContext.Provider value={{ ...state, login, logout }}>
            {children}
        </AuthContext.Provider>
    );

}

export { AuthContext, AuthProvider };
