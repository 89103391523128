import React, { useEffect } from "react";
import Page from "components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import {FieldArray, FormikProvider, useFormik} from "formik";
import PageWrapper from "../../components/PageContent/PageWrapper";
import {Button, Grid, styled, Typography} from "@mui/material";
import MyTextInput from "../../components/Forms/MyTextInput";
import BtnLoading from "../../components/Buttons/BtnLoading";
import Alert from "@mui/material/Alert";
import {LocationType} from "../../models/company";
import TopToolBar from "../../components/PageContent/TopToolBar";
import MySelectInput from "../../components/Forms/MySelectInput";
import PageTitle from "../../components/PageContent/PageTitle";
import {createCompany} from "../../services/userService";
import {useNavigate} from "react-router-dom";

export default function CreateCompanyPage() {
    const { t } = useLocales();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues:{
            name: '',
            url: '',
            locations: []

        },
        onSubmit: values =>{
            createCompany(values).then((res)=>{
                navigate('/admin/companies');
            }).catch((error)=>{
                if(error.validation){
                    formik.setErrors(error.validation);
                }
            })
        }
    })

    const DeleteButton = styled(Button)(() => ({
        color: "#fff",
        background: "#DF2323",
        padding: "10px 30px",
        borderRadius: "30px",
        border: "none",
        "&:hover": {
            background: "#A51414",
            color: "#fff"
        }
    }));

    useEffect(() => {

    }, []);


    return (
        <Page title={"Opret firma"}>
            <PageWrapper>
                <PageTitle title={"Opret firma"} />
                <FormikProvider value={formik}>
                    <Grid container sx={{marginTop: '100px'}}>
                        <Grid item xs={7}>
                            <MyTextInput name="name" label="Navn"/>
                        </Grid>
                        <Grid item xs={7}>
                            <MyTextInput name="url" label="Hjemmeside"/>
                        </Grid>
                        <FieldArray name="locations">
                            {({ push, remove }) => (
                                <>
                                    <Grid item xs={7} sx={{margin: '30px 0'}}>
                                        <TopToolBar>
                                        <Typography variant="h6">Locations</Typography>
                                        <BtnLoading size="small" onClick={()=>push({name: '', type: LocationType.External})} variant="contained" label="tilføj lokation" color="secondary" />
                                        </TopToolBar>
                                    </Grid>
                                    {formik.values.locations.length > 0 && (formik.values.locations.map((z, index) => (
                                        <Grid container maxWidth={1350} spacing={4} key={index} sx={{ marginBottom: "20px", alignItems: 'center' }}>
                                            <Grid item md={5}>
                                                <MyTextInput name={`locations.${index}.name`} label="name" />
                                            </Grid>
                                            <Grid item md={5}>
                                                <MySelectInput values={['Intern', 'Ekstern']} name={`locations.${index}.type`} />
                                            </Grid>
                                            <Grid item md={2}>
                                                <DeleteButton onClick={() => remove(index)}>{t("general.remove")}</DeleteButton>
                                            </Grid>
                                        </Grid>
                                    )))}

                                    {formik.errors.locations && !Array.isArray(formik.errors.locations) &&
                                        <Alert sx={{ width: "100%", flex: "0 1 100%" }} color="error">{formik.errors.locations}</Alert>}
                                </>
                            )}

                        </FieldArray>
                        <Grid item xs={12}>
                            <BtnLoading size="large" onClick={formik.handleSubmit} variant="contained" label="opret" color="primary" />
                        </Grid>
                    </Grid>
                </FormikProvider>

            </PageWrapper>
        </Page>
    );


}
