import React, { useState } from "react";
import { useField } from "formik";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Probs {
  placeholder?: string;
  name: string,
  label? : string
  required? : boolean
  transparent?: boolean
}

export default function MyPasswordField({ label, placeholder, name, required, transparent, ...rest }: Probs){
  const [field, meta] = useField(name)
  const [passwordShown, setPasswordShown] = useState(false);


  return (
    <FormControl fullWidth  sx={{ margin: '0 0 20px' }}>
      <InputLabel htmlFor="outlined-adornment-password">{placeholder}</InputLabel>

      <OutlinedInput
        placeholder={placeholder}
        type={passwordShown ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=>setPasswordShown(!passwordShown)}
              onMouseDown={(event)=>  event.preventDefault()}
              edge="end"
            >
              {passwordShown ? <VisibilityOff color="info"/> : <Visibility color="primary" />}
            </IconButton>
          </InputAdornment>
        }
        id={name}
        label={label}
        style={{ background: transparent ? '#fff': 'transparent', marginTop:5 }}
        {...field} {...rest}/>

      {meta.touched && meta.error ? (
        <Alert severity="error">{meta.error}</Alert>
      ): null}
    </FormControl>



  )
}
