import React, { FunctionComponent, ReactNode } from "react";
import { styled } from "@mui/material/styles";

interface OwnProps {
  children: ReactNode
}

type Props = OwnProps;

const Wrapper = styled('div')(() => ({
  display: 'block',
  padding: '30px 45px'
}));

const PageWrapper: FunctionComponent<Props> = ({ children }) => (
    <Wrapper>
      {children}
    </Wrapper>
  );

export default PageWrapper;
