import React from 'react'
import { styled } from "@mui/material/styles";

interface Props {
    children: React.ReactNode
}


const Wrapper = styled('div')(({ theme }) => ({
  display: "block"
}));
const ListItemBtnAside: React.FC<Props> = ({ children }) => (
    <Wrapper>{children}</Wrapper>
  )

export default ListItemBtnAside
