import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

const Wrapper = styled('div')(({theme}) => ({
    display: 'flex',
    maxWidth: '600px',

    flexDirection: 'column',
    background: '#daffef',
    borderRadius: '20px',
    overflow: 'hidden',
    justifyContent: 'space-between'
}));

const ImageWrapper = styled('div')(() => ({
    height: '200px',
    overflow: 'hidden',
    background: 'rgb(3, 178, 103)',
    'img': {
        width: '100%',
        height: 'auto',
    }
}));


const ContentWrapper = styled('div')(() => ({
    padding: '30px 20px'
}));

const ButtonWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    'button': {
        borderRadius: '0'
    }

}));

const DeleteButton = styled(Button)(() => ({
    color: "#fff",
    background: "#DF2323",
    padding: "7px 30px",
    borderRadius: "30px",
    border: "none",
    "&:hover": {
        background: "#A51414",
        color: "#fff"
    }
}));



export default {Wrapper, ImageWrapper,DeleteButton, ContentWrapper, ButtonWrapper}
