import React from 'react'

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {  Typography } from '@mui/material'
import Cross from 'assets/icons/undraw_throw_away_re_x60k.svg'
import Dialog from '@mui/material/Dialog';
import BtnAction from './Buttons/BtnAction';
import useLocales from "../hooks/useLocales";


interface Probs {
    openDialog: boolean,
    title: string,
    danger?: boolean,
    message: string,
    close: any,
    proceed: any
}


export default function ConfirmationDialog({ openDialog, close, proceed, danger, title, message }: Probs) {
    const { translate } = useLocales()


    return (
        <Dialog open={openDialog} onClose={close}>
        <DialogContent style={{ textAlign: "center", padding: "30px 50px" }}>
            <img src={Cross} alt="" width="250px"/>
            <Typography component="h5" variant="h5" style={{ marginTop: 30, marginBottom:30, color: danger ? "#CA0B00": "#e5e5e5" }} > {title}</Typography>
            <Typography component="p" variant="body2" >{message} </Typography>
            <DialogActions>
            <BtnAction
              onClick={close}
              label={translate('general.cancel')}
              color="info"
              variant="outlined"
              />
               <BtnAction
              onClick={proceed}
              label={translate('general.yesDelete')}
              color="error"
              variant="outlined"
              />
             </DialogActions>
        </DialogContent>
        </Dialog>
    )
}
