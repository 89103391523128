import React, { useEffect, useState } from "react";
import Page from "components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import PageTitle from "../../components/PageContent/PageTitle";
import PageWrapper from "../../components/PageContent/PageWrapper";
import {Company} from "../../models/company";
import {Grid, Typography} from "@mui/material";
import { useParams } from "react-router";
import {getCompany, getCompanyVehicles} from "../../services/userService";
import {Car} from "../../models/car";
import SmallLoading from "../../components/Loadings/SmallLoading";
import ListItemWrapper from "../../components/ListItemComponents/ListItemWrapper";
import ListItemTitlesWrapper from "../../components/ListItemComponents/ListItemTitlesWrapper";
import ListItemTitle from "../../components/ListItemComponents/ListItemTitle";
import ListItemBtnAside from "../../components/ListItemComponents/ListItemBtnAsideBottom";
import BtnLink from "../../components/Buttons/BtnLink";
import {RemoveRedEye} from "@mui/icons-material";
import Vehicle from "../../components/Vehicle";
import BtnAction from "../../components/Buttons/BtnAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";


export default function CompanyDetails() {
    const { t } = useLocales();
    const { id } = useParams();
    const [company, setCompany] = useState<Company>();
    const [cars, setcars] = useState<Car[]>();

    useEffect(() => {
        if(id){
            getCompany(id).then((res)=>{
                setCompany(res);
                getCompanyVehicles(res.id!).then((res)=>{
                    setcars(res);
                })
            })
        }
    }, []);


    return (
        <Page title={t("menu.companies")}>
            <PageWrapper>
                {company &&
                    <>
                        <PageTitle title={company.name} />

                        {!cars && <SmallLoading />}

                        <Grid container  spacing={4}>
                            {cars && cars.length > 0 && cars.map((vehicle)=>
                                <Grid item md={2} key={vehicle.id}>
                                    <Vehicle.Wrapper>
                                        <Vehicle.ImageWrapper>
                                            <img src={vehicle.thumbnail} alt={vehicle.name}/>
                                        </Vehicle.ImageWrapper>
                                        <Vehicle.ContentWrapper>
                                            <Typography variant="h4" component="h3">{vehicle.name}</Typography>
                                        </Vehicle.ContentWrapper>
                                    </Vehicle.Wrapper>
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
            </PageWrapper>
        </Page>
    );
}
