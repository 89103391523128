import React from 'react'
import { styled } from "@mui/material/styles";

interface Props {
    children: React.ReactNode
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexWrap: 'wrap',

  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
}));
const ListItemTitlesWrapper: React.FC<Props> = ({ children }) => (
    <Wrapper>{children}</Wrapper>
  )

export default ListItemTitlesWrapper
