// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: getIcon("ic_dashboard"),
  users: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  products: getIcon("ic_product"),
  locations: getIcon("ic_locations"),
  settings: getIcon("ic_settings"),
};

const User = [
  {
    subheader: "menu.navigation",
    items: [
      { title: "menu.dashboard", path: '/account', icon: ICONS.dashboard },
      { title: "menu.cars", path: '/account/cars', icon: ICONS.ecommerce },

    ]
  }
];

const Admin = [
  {
    subheader: "menu.navigation",
    items: [
      { title: "menu.dashboard", path: '/admin/dashboard', icon: ICONS.dashboard },
      { title: "menu.companies", path:'/admin/companies', icon: ICONS.products },
      { title: "menu.users", path: '/admin/users', icon: ICONS.users },
    ]
  }
];



export default {User, Admin};
