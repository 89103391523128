function path(root: string, sublink: string) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';


export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    catalog: path(ROOTS_DASHBOARD, '/catalog'),
    products: path(ROOTS_DASHBOARD, '/catalog/products'),
    categories: path(ROOTS_DASHBOARD, '/catalog/categories'),
    manufacturers: path(ROOTS_DASHBOARD, '/catalog/manufacturers'),
    sales: path(ROOTS_DASHBOARD, '/sales'),
    locations: path(ROOTS_DASHBOARD, '/locations'),
    customers: path(ROOTS_DASHBOARD, '/customers'),
    pos: path(ROOTS_DASHBOARD, '/pos'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    user: {
        account: path(ROOTS_DASHBOARD, 'profile'),
        profile: path(ROOTS_DASHBOARD, 'profile'),
    }
};

export const PAPP_HOMEPAGE = 'https://www.sabby.dk';
