import { durationType,  permitEndReason, PermitEndReason } from "helpers/Constants";
import { addDays, addHours, addMonths } from "date-fns";

export const getPermitEndReason = (type: PermitEndReason | null) => {
    if(type == null)
        return 'permit.endReason.Valid'
    return permitEndReason[type];
}

export const getEndDate = (date: Date | null, duration: durationType, durationValue: number) : Date | null=> {
    if(duration !== durationType.None && date != null){
        switch(duration){
            case durationType.Months:
                return addMonths(date, durationValue)
            case durationType.Hours:
                return addHours(date, durationValue)
            case durationType.Days:
                return  addDays(date, durationValue)
        }
    }
    return null

}


export const  camelize = (str: string) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '')
