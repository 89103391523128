// hooks

// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';
import { useContext } from 'react';
import {AuthContext} from "../context/AuthContext";
import {Auth0ContextType} from "../@types/auth";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useContext(AuthContext) as Auth0ContextType;

  return (
    <Avatar
      alt={user?.name}
      color={'default' }
      {...other}
    >
      {createAvatar(user?.name!).name}
    </Avatar>
  );
}
