import React  from 'react'
import { Link } from 'react-router-dom'
import { styled } from "@mui/material/styles";

interface Props {
    title?: string
    subTitle?:  string
    subTitleLink?: string
    subTitleLabel?:string
    titleVariant: 'light' | 'bold'
    subTitleVariant: 'light' | 'bold'
    renderSubTitles?: JSX.Element[] | JSX.Element | string | boolean | undefined
}

export const ListItemTitleWrapper = styled('div')(({ theme }) => ({
    width: '250px',
    //marginBottom: "10px",
    '& p':{
        maxWidth: "235px",
        wordWrap: 'break-word'
    },
    '& .bold': {
        fontWeight: '400',
        //marginTop: "10px"
    },
    '& .light': {
        fontWeight: '200',
    },
    '& span':{
        display: 'block',
        //marginBottom: "10px"
    },

    "& a": {
        display: 'inline-block',
        margin: '10px 0',
        textDecoration: "none",
        color: "#1976D2",
        background: theme.palette.info.light,
        padding: '4px 10px',
        borderRadius: '20px'
    }
}));
const ListItemTitle: React.FC<Props> = ({
    title,
    subTitle,
    subTitleLink,
    subTitleLabel,
    titleVariant,
    subTitleVariant,
    renderSubTitles

}) => (
      <ListItemTitleWrapper>
        <p className={titleVariant}>{title}</p>

          {subTitle &&  <p className={subTitleVariant}>
            {subTitle}
          </p>}
          {subTitleLink &&

              <Link to={subTitleLink}>
                {subTitleLabel}
              </Link>
          }
          {renderSubTitles !== undefined && <p className={subTitleVariant}>{renderSubTitles}</p>}
      </ListItemTitleWrapper>


  )

export default ListItemTitle
