import React from "react";
import { useField } from "formik";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";

interface Probs {
    placeholder?: string;
    name: string,
    label? : string
    required? : boolean
    white?: boolean
    labelAbove?: boolean
}

export default function MyTextInput({ label, placeholder, name, required, white,labelAbove, ...rest }: Probs){
    const [field, meta] = useField(name)
    return (
            <FormControl fullWidth error={meta.touched && !!meta.error}>
                {labelAbove && label && <Typography sx={{fontWeight: "700", fontSize: 16}}>{label}</Typography>}
            <TextField
            variant="outlined"
            margin="normal"
            id={name}
            sx={{'.MuiInputBase-root':{background: white ? '#fff': 'transparent'}}}
            label={label && !labelAbove ?  label: undefined}
            placeholder={placeholder}
            style={{ marginTop:5 }}
            {...field} {...rest}/>
                {meta.touched && meta.error ? (
                  <Alert severity="error">{meta.error}</Alert>
                ): null}
            </FormControl>



    )
}
