import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
interface Props  {
    url: string
    label?: string
    IconColor?: "info" | "primary"| "secondary" |  "success" |  "danger"
    Icon?: any
    round?: boolean
    fullWidth?: boolean,
    btnType: "text" | "outlined" | "contained" | undefined
    color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
    size?: 'small' | 'medium' | 'large' | undefined
  }

const BtnLink: React.FC<Props>  = ({ url, label, Icon, btnType, round, fullWidth, color, size }) => (
      <Button component={Link} startIcon={Icon} fullWidth={fullWidth} to={url}  variant={btnType} color={color} size={size ? size : "medium"}>
        {label}
      </Button>
  )

export default BtnLink
