import React, {FunctionComponent, useContext, useEffect} from 'react';
import {
    CarDetail,
    CarDetailApiResponse,
    carType,
    CreateVehicleRequest,
    gearType,
    makes,
    Media,
    propellantTypes,
    years
} from "../models/car";
import {FieldArray, FormikProvider, useFormik} from "formik";
import {createCompanyVehicle} from "../services/userService";
import snackBarUtils from "../utils/SnackBarUtils";
import PageWrapper from "../components/PageContent/PageWrapper";
import PageTitle from "../components/PageContent/PageTitle";
import {Box, Checkbox, FormControlLabel, Grid, MenuItem, Select} from "@mui/material";
import MySelectInput from "../components/Forms/MySelectInput";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import MyTextInput from "../components/Forms/MyTextInput";
import MyNumberInput from "../components/Forms/MyNumberInput";
import MyCheckBoxInput from "../components/Forms/MyCheckBoxInput";
import MyTextAreaInput from "../components/Forms/MyTextAreaInput";
import {FileDrop} from "./FileDrop";
import {ReactSortable} from "react-sortablejs";
import Vehicle from "../components/Vehicle";
import BtnLoading from "../components/Buttons/BtnLoading";
import Page from "../components/PageContent/Page";
import {AuthContext} from "../context/AuthContext";
import {Auth0ContextType, CompanyLocation} from "../@types/auth";
import useLocales from "../hooks/useLocales";

interface OwnProps {
    vehicle: CarDetailApiResponse
    locations: CompanyLocation[]
    handleSubmit: (data : CarDetail) => void,
    handleRemoveImage: (id: string) => void,
}

type Props = OwnProps;

const CarForm: FunctionComponent<Props> = ({vehicle, locations, handleSubmit, handleRemoveImage}) => {
    const {t} = useLocales();

    const formik = useFormik({
        initialValues: {
            carType: vehicle.carType,
            locationId: vehicle.locationId,
            make: vehicle.make,
            model: vehicle.model,
            variant: vehicle.variant,
            year: vehicle.year,
            motor: vehicle.motor,
            mileAge: vehicle.mileAge,
            kilometerPerLiter: vehicle.kilometerPerLiter,
            gearType: vehicle.gearType,
            propellantType: vehicle.propellantType,
            hk: vehicle.hk,
            forLeasing: vehicle.forLeasing,
            forSale: vehicle.forSale,
            leasing: vehicle.leasingData,
            salePrice: vehicle.salePrice,
            comment: vehicle.comment,
            images: [],
            files: []
        } as CarDetail,
        onSubmit: handleSubmit,
    })


  return (
          <FormikProvider value={formik}>
              <Grid container spacing={4} sx={{marginTop: '50px'}}>
                  <Grid item xs={12} md={8}>
                      <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                              <MySelectInput name="carType" values={carType}/>
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <FormControl fullWidth
                                           error={formik.touched.locationId && !!formik.errors.locationId}>
                                  <Select
                                      variant="outlined"
                                      id={"locationId"}
                                      placeholder={"locationId"}
                                      value={formik.values.locationId}
                                      onChange={(value) => formik.setFieldValue('locationId', value.target.value)}
                                      style={{background: '#fff', width: '100%', marginTop: 5}}>
                                      {locations.map((value) => (
                                          <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                              {formik.touched.locationId && formik.errors.locationId ? (
                                  <Alert severity="error">{formik.errors.locationId}</Alert>
                              ) : null}

                          </Grid>
                          <Grid item xs={12} md={3}>
                              <FormControl fullWidth error={formik.touched.make && !!formik.errors.make}>
                                  <Select
                                      variant="outlined"
                                      id={"Make"}
                                      placeholder={"Make"}
                                      value={formik.values.make}
                                      onChange={(value) => formik.setFieldValue('make', value.target.value)}
                                      style={{background: '#fff', width: '100%', marginTop: 5}}>
                                      {makes.map((value) => (
                                          <MenuItem key={value} value={value}>{value}</MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                              {formik.touched.make && formik.errors.make ? (
                                  <Alert severity="error">{formik.errors.make}</Alert>
                              ) : null}

                          </Grid>

                          <Grid item xs={12} md={3}>
                              <MyTextInput name="model" label="model"/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                              <MyTextInput name="variant" label="Variant"/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                              <FormControl fullWidth error={formik.touched.year && !!formik.errors.year}>
                                  <Select
                                      variant="outlined"
                                      id={"year"}
                                      placeholder={"year"}
                                      value={formik.values.year}
                                      onChange={(value) => formik.setFieldValue('year', value.target.value)}
                                      style={{background: '#fff', width: '100%', marginTop: 5}}>
                                      {years.map((value) => (
                                          <MenuItem key={value} value={value}>{value}</MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                              {formik.touched.make && formik.errors.make ? (
                                  <Alert severity="error">{formik.errors.make}</Alert>
                              ) : null}

                          </Grid>
                          <Grid item xs={12} md={4}>
                              <MyTextInput name="motor" label="Motor"/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <MyNumberInput name="mileAge" label="Kilometerstand" />
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <MyTextInput name="kilometerPerLiter" label={'Kilometer pr. liter'}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <MySelectInput name="gearType" values={gearType}/>
                          </Grid>

                          <Grid item xs={12} md={4}>
                              <MySelectInput name="propellantType" values={propellantTypes}/>
                          </Grid>

                          <Grid item xs={12} md={4}>
                              <MyTextInput name="hk" label={'HK / NM'}/>
                          </Grid>
                          <Grid item xs={12}>
                              <FormControl error={formik.touched.forLeasing && !!formik.errors.forLeasing}>
                                  <FormControlLabel label={"For leasing"} style={{color: '#3d405b'}}
                                                    control={<Checkbox
                                                        id={"forLeasing"}
                                                        style={{margin: "10px 5px 10px 0px"}}
                                                        checked={formik.values.forLeasing}
                                                        onChange={(value, va) => {
                                                            formik.setFieldValue('forLeasing', va);
                                                            if (va) {
                                                                formik.setFieldValue('leasing', {
                                                                    leasingType: "Erhvervsleasing",
                                                                    residualValue: 40000,
                                                                    downPayment: 40000,
                                                                    leasingPrice: 2000,
                                                                    duration: "12 Måneder",
                                                                })
                                                            } else {
                                                                formik.setFieldValue('leasing', undefined)
                                                            }
                                                        }}
                                                    />}/>
                                  {formik.touched.forLeasing && formik.errors.forLeasing ? (
                                      <Alert severity="error">{formik.errors.forLeasing}</Alert>
                                  ) : null}
                              </FormControl>
                          </Grid>
                          {formik.values.forLeasing &&
                              <>
                                  <Grid item xs={4}>
                                      <FormControl fullWidth error={formik.touched.year && !!formik.errors.year}>
                                          <Select
                                              variant="outlined"
                                              id={"year"}
                                              placeholder={"year"}
                                              value={formik.values.leasing?.leasingType}
                                              onChange={(value) => formik.setFieldValue('leasing.leasingType', value.target.value)}
                                              style={{background: '#fff', width: '100%', marginTop: 5}}>

                                              <MenuItem value={"Erhvervsleasing"}>Erhvervsleasing</MenuItem>

                                          </Select>
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={4}>
                                      <MyNumberInput name="leasing.downPayment" label={'Udbetaling'}/>
                                  </Grid>

                                  <Grid item xs={4}>
                                      <MyNumberInput name="leasing.residualValue" label={'Restværdi'}/>
                                  </Grid>
                                  <Grid item xs={4}>
                                      <MyNumberInput name="leasing.leasingPrice" label={'Månedlig pris'}/>
                                  </Grid>
                                  <Grid item xs={4}>
                                      <MyTextInput name="leasing.duration" label={'varighed'}/>
                                  </Grid>
                              </>
                          }
                          <Grid item xs={12}>
                              <MyCheckBoxInput name={'forSale'} checked={formik.values.forSale}
                                               label={"TIL SALG"}/>
                          </Grid>
                          {formik.values.forSale &&
                              <Grid item xs={12} md={4}>
                                  <MyNumberInput name="salePrice" label={'Salgsprisen'}/>
                              </Grid>
                          }

                          <Grid item xs={12} md={12}>
                              <MyTextAreaInput name="comment" label="Kommentar"/>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                      <FieldArray name="files">
                          {({push, remove}) => (
                              <Grid container spacing={2}>
                                  <Grid item xs={12} sx={{marginBottom: '60px'}}>
                                      <FileDrop handleResult={(values) => {
                                          if (formik.values.files) {
                                              const media : Media[] = [];
                                              values.forEach((file: File,index: number) =>{
                                                  media.push({
                                                      id: index,
                                                      index: index,
                                                      file: file,
                                                  })
                                              })

                                              formik.setFieldValue('files', [...formik.values.files, ...media])
                                          }
                                      }}/>
                                  </Grid>
                                  <ReactSortable className={"list"} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} list={formik.values.files!} setList={(values)=> formik.setFieldValue('files', values) }>
                                      {formik.values.files && formik.values.files.map((item, index) =>
                                          <Box key={item.id}
                                               sx={{maxWidth: '33.33%', background: '#88ed81', overflow: 'hidden', flex: '0 1 33.33%', padding: '10px',
                                                   marginBottom: '20px','img': {flex: '0 1 40%', maxWidth: '100%', height: 'auto'}, 'button': {
                                                       width: '100%', borderRadius: '0'
                                                   }}}>
                                              <img alt="" src={URL.createObjectURL(item.file)}/>
                                              <Vehicle.DeleteButton onClick={() => remove(index)}>{t("general.remove")}</Vehicle.DeleteButton>
                                          </Box>)}
                                  </ReactSortable>

                                  {vehicle.images && vehicle.images.map((item) =>
                                      <Box key={item.id}
                                           sx={{maxWidth: '33.33%', overflow: 'hidden', flex: '0 1 33.33%', padding: '10px',
                                               marginBottom: '20px','img': {flex: '0 1 40%', maxWidth: '100%', height: 'auto'}, 'button': {
                                                   width: '100%', borderRadius: '0'
                                               }}}>
                                          <img alt="" src={item.url}/>
                                          <Vehicle.DeleteButton onClick={() => handleRemoveImage(item.id)}>{t("general.remove")}</Vehicle.DeleteButton>
                                      </Box>
                                  )}
                              </Grid>
                          )}
                      </FieldArray>
                  </Grid>
                  <Grid item xs={12}>
                      <BtnLoading size="large" onClick={formik.handleSubmit} variant="contained" label="Opdater"
                                  color="primary"/>
                  </Grid>
              </Grid>
          </FormikProvider>);
};

export default CarForm;
