export interface Company {
    id?: string;
    name: string;
    url: string;
    locations: LocationModel[]
}

export interface LocationModel {
    id?: string;
    name: string;
    locationType: LocationType
}

export enum LocationType {
    Internal,
    External
}

