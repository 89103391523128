import axios, { AxiosError, AxiosResponse } from 'axios';
import { camelize } from "../utils/helperFunctions";
import snackBarUtils from "../utils/SnackBarUtils";

let apiClient = axios.create(
  {
      baseURL: process.env.REACT_APP_API_URL
  }
)

apiClient.interceptors.request.use(async config => {
    const token = await window.localStorage.getItem('token');
    if (token != null) {
        config.headers = {
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json;'
        }
    }
    return config;
});

apiClient.interceptors.response.use(async response => response, ((error: AxiosError) => {
    const { data, status } = error.response!;
    const axiosConfig = error.config;
    switch (status) {
        case 400:
            if (data.errors) {
                const newError : any = { };
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        let newKey  = key.replace('$.', '')
                        newKey = camelize(newKey);
                        newError[newKey] = data.errors[key].join('. ')
                    }
                }
                return Promise.reject({ validation:newError });
            } else if(data.message){
                return Promise.reject({ error: data.message });
            }

            return Promise.reject("Der opstod en uventet fejl! Kontakt supporten")

        case 409:
            snackBarUtils.error("Der opstod en konflikt")
            break;
        case 401:
            snackBarUtils.error("401")
            break;
        case 404:
            snackBarUtils.error('404');
            break;
        case 403:
            window.location.replace('/403')
            break;
        case 405:
            snackBarUtils.error("Der opstod en fejl");
            break;
        case 500:
            snackBarUtils.error("Der opstod en uventet fejl! Kontakt supporten!");
            return Promise.reject({ error: "Der opstod en uventet fejl! Kontakt supporten" });
    }

    return Promise.reject({ error:"Der opstod en uventet fejl! Kontakt supporten" });

}))


const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
    get: <T>(url: string) => apiClient.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => apiClient.post<T>(url, body).then(responseBody),
    postFile: (url: string) => apiClient.post(url, {}, { responseType: "blob" }).then(responseBody),
    put: <T>(url: string, body: {}) => apiClient.put<T>(url, body).then(responseBody),
    delete: <T>(url: string) => apiClient.delete<T>(url).then(responseBody),
}


const api = {
    request,
    apiClient
}

export default api;
