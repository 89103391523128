import React, {useEffect, useState} from "react";
import Page from "components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import PageTitle from "../../components/PageContent/PageTitle";
import PageWrapper from "../../components/PageContent/PageWrapper";
import BtnLink from "../../components/Buttons/BtnLink";
import ListItemWrapper from "components/ListItemComponents/ListItemWrapper";
import ListItemTitlesWrapper from "components/ListItemComponents/ListItemTitlesWrapper";
import ListItemTitle from "components/ListItemComponents/ListItemTitle";
import ListItemBtnAside from "components/ListItemComponents/ListItemBtnAsideBottom";
import { Company } from "models/company";
import {RemoveRedEye} from "@mui/icons-material";
import TopToolBar from "../../components/PageContent/TopToolBar";
import {getCompanies} from "../../services/userService";
import SmallLoading from "../../components/Loadings/SmallLoading";


export default function CompaniesPage() {
    const { t } = useLocales();
    const [companies, setCompanies] = useState<Company[]>();

    useEffect(() => {
        getCompanies().then((res)=>{
            setCompanies(res);
        }).catch((err)=>{
        })

    }, []);


    return (
        <Page title={t("menu.companies")}>
            <PageTitle title={t("menu.companies")} />
            <PageWrapper>
                <TopToolBar> <BtnLink url={'/admin/companies/create'} btnType="contained" color="primary" label={'OPRET'}/></TopToolBar>
                {!companies && <SmallLoading />}
                {companies && companies.length > 0 && companies.map((item)=>
                    <ListItemWrapper bgcolor="blue" border={true}  key={item.id}>
                    <ListItemTitlesWrapper>
                        <ListItemTitle title={t('general.name') + ':'} titleVariant='bold' subTitleVariant='light' subTitle={item.name} />
                    </ListItemTitlesWrapper>
                    <ListItemBtnAside>
                        <BtnLink url={`${item.id}`} btnType="contained" color="info" Icon={<RemoveRedEye />} label={t('general.view')}/>
                    </ListItemBtnAside>
                </ListItemWrapper>)}
            </PageWrapper>
        </Page>
    );
}
