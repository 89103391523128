import React, { FunctionComponent, useEffect } from 'react';
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";

interface OwnProps {
}

type Props = OwnProps;

const RedirectToLogin: FunctionComponent<Props> = (props) => {
    const {  isAuthenticated, user, isInitialized } =  useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        if (!isAuthenticated){
            navigate('/login' , {replace: true})
        }
    }, []);

    return <LoadingScreen />;
};

export default RedirectToLogin;
