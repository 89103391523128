import React, { useEffect } from "react";
import Page from "components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import {FieldArray, FormikProvider, useFormik} from "formik";
import PageWrapper from "../../components/PageContent/PageWrapper";
import {Button, Grid, styled, Typography} from "@mui/material";
import MyTextInput from "../../components/Forms/MyTextInput";
import BtnLoading from "../../components/Buttons/BtnLoading";
import Alert from "@mui/material/Alert";
import {LocationType} from "../../models/company";
import TopToolBar from "../../components/PageContent/TopToolBar";
import MySelectInput from "../../components/Forms/MySelectInput";
import PageTitle from "../../components/PageContent/PageTitle";
import {createCompany, createUser} from "../../services/userService";
import {useNavigate} from "react-router-dom";


export default function CreateUserPage() {
    const { t } = useLocales();
    const navigate = useNavigate();


    const formik = useFormik({
        initialValues:{
            name: '',
            companyId: '',
            email: ''

        },
        onSubmit: values =>{
            createUser(values).then((res)=>{
                navigate('/admin/users');
            }).catch((error)=>{
                if(error.validation){
                    formik.setErrors(error.validation);
                }
            })
        }
    })

    const DeleteButton = styled(Button)(() => ({
        color: "#fff",
        background: "#DF2323",
        padding: "10px 30px",
        borderRadius: "30px",
        border: "none",
        "&:hover": {
            background: "#A51414",
            color: "#fff"
        }
    }));

    useEffect(() => {

    }, []);


    return (
        <Page title={"Opret bruger"}>
            <PageWrapper>
                <PageTitle title={"Opret bruger"} />
                <FormikProvider value={formik}>
                    <Grid container sx={{marginTop: '100px'}}>
                        <Grid item xs={7}>
                            <MyTextInput name="name" label="Navn"/>
                        </Grid>
                        <Grid item xs={7}>
                            <MyTextInput name="email" label="email"/>
                        </Grid>
                        <Grid item xs={7}>
                            <MyTextInput name="companyId" label="virksomhedsid"/>
                        </Grid>

                        <Grid item xs={12}>
                            <BtnLoading size="large" onClick={formik.handleSubmit} variant="contained" label="opret" color="primary" />
                        </Grid>
                    </Grid>
                </FormikProvider>

            </PageWrapper>
        </Page>
    );


}
