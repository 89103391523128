import React from 'react'
import {Button, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";


interface Props {
    onClick?: () => void
    disabled?: boolean
    label?: string
    type?: any
    Icon?: any
    toolTip?: boolean
    fullWidth?: boolean,
    uppercase?: boolean,
    variant: "text" | "outlined" | "contained" | undefined
    color?: "info" | "primary" | "secondary" | "success" | "inherit" | "error" | "warning" | undefined
    size?: "small" | "medium" | "large" | undefined
}

const BtnAction: React.FC<Props> = ({
                                        onClick,
                                        label,
                                        uppercase,
                                        toolTip,
                                        Icon,
                                        variant,
                                        fullWidth,
                                        disabled,
                                        color,
                                        type,
                                        size
                                    }) => {

    if (toolTip) {
        return (
            <Tooltip title={label}>
                <Button
                    disabled={disabled}
                    fullWidth={fullWidth ?? false}
                    color={color}
                    variant={variant}
                    startIcon={Icon}
                    type={type}
                    size={size}
                    onClick={onClick}
                    sx={{

                        textTransform: "uppercase",
                        ...(label === undefined || toolTip ? {
                            minWidth: 'auto',
                            '.MuiButton-startIcon': {
                                margin: '0'
                            }
                        } : {})
                    }}/>
            </Tooltip>
        )
    }

    return (<Button
            disabled={disabled}
            fullWidth={fullWidth ?? false}
            color={color}
            variant={variant}
            startIcon={Icon}
            type={type}
            size={size}
            onClick={onClick}
            sx={{
                padding: '10px 60px',
                textTransform: uppercase ? "uppercase" : 'none',
                ...(label === undefined ? {
                    minWidth: 'auto',
                    '.MuiButton-startIcon': {
                        margin: '0'
                    }
                } : {})
            }}>

            {label}
        </Button>
    )
}

export default BtnAction
