import React, {useEffect, useState} from "react";
import Page from "components/PageContent/Page";
import useLocales from "../../hooks/useLocales";
import PageTitle from "../../components/PageContent/PageTitle";
import PageWrapper from "../../components/PageContent/PageWrapper";
import TopToolBar from "../../components/PageContent/TopToolBar";
import BtnLink from "../../components/Buttons/BtnLink";
import ListItemWrapper from "../../components/ListItemComponents/ListItemWrapper";
import ListItemTitlesWrapper from "../../components/ListItemComponents/ListItemTitlesWrapper";
import ListItemTitle from "../../components/ListItemComponents/ListItemTitle";
import ListItemBtnAside from "../../components/ListItemComponents/ListItemBtnAsideBottom";
import {RemoveRedEye} from "@mui/icons-material";
import SmallLoading from "../../components/Loadings/SmallLoading";
import {UserResponseList} from "../../models/user";
import {getUsers} from "../../services/userService";


export default function AllUsers() {
  const { t } = useLocales();
    const [users, setUsers] = useState<UserResponseList[]>();

    useEffect(() => {
        getUsers().then((res)=>{
            setUsers(res)
        }).catch((err)=>{

        })
    }, []);



    return (
    <Page title={t("menu.users")}>
      <PageTitle title={t("general.users")} />
            <PageWrapper>
                <TopToolBar> <BtnLink url={'/admin/users/create'} btnType="contained" color="primary" label={'OPRET'}/></TopToolBar>
                {!users && <SmallLoading />}
                {users && users.length > 0 && users.map((item)=>
                    <ListItemWrapper bgcolor="blue" border={true}  key={item.id}>
                        <ListItemTitlesWrapper>
                            <ListItemTitle title={t('general.company') + ':'} titleVariant='bold' subTitleVariant='light' subTitle={item.companyName ?? "INGEN VIRKSOMHED TILKNYTTET"} />
                        </ListItemTitlesWrapper>
                        <ListItemTitlesWrapper>
                            <ListItemTitle title={t('general.name') + ':'} titleVariant='bold' subTitleVariant='light' subTitle={item.name} />
                        </ListItemTitlesWrapper>
                        <ListItemTitlesWrapper>
                            <ListItemTitle title={t('general.email') + ':'} titleVariant='bold' subTitleVariant='light' subTitle={item.email} />
                        </ListItemTitlesWrapper>
                        <ListItemBtnAside>
                            <BtnLink url={`${item.id}`} btnType="contained" color="info" Icon={<RemoveRedEye />} label={t('general.edit')}/>
                        </ListItemBtnAside>
                    </ListItemWrapper>)}
            </PageWrapper>
    </Page>
  );
}
