import { useField } from "formik";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";

import Alert from '@mui/material/Alert';

interface Probs {
    placeholder?: string;
    name: string,
    label? : string
    checked: boolean
}

export default function MyCheckBoxInput(probs: Probs){
    const [field, meta] = useField(probs.name)

    return (
        <FormControl error={meta.touched && !!meta.error}>
        <FormControlLabel  label={probs.label} style={{ color: '#3d405b' }} control={<Checkbox
          id={probs.name}
          label={probs.label}
          style={{ margin:"10px 5px 10px 0px" }}
          {...field} {...probs}/>}/>
            {meta.touched && meta.error ? (
          <Alert severity="error">{meta.error}</Alert>
        ): null}
        </FormControl>
    )
}
