import React, { FunctionComponent } from 'react';
import LoadingButton from "@mui/lab/LoadingButton";

interface OwnProps {
  onClick?: () => void
  disabled?: boolean
  label?: string
  type?: any
  Icon?: any
  isSubmitting?: boolean,
  fullWidth?: boolean,
  variant: "text" | "outlined" | "contained" | undefined
  color?: "info" | "primary" | "secondary" | "success" | "inherit" | "error" | "warning" | undefined
  size?: "small" | "medium" | "large" | undefined
}

type Props = OwnProps;

const BtnLoading: FunctionComponent<Props> = ({
                                                onClick,
                                                disabled,
                                                type,
                                                label,
                                                isSubmitting,
                                                variant,
                                                color,
                                                size,
                                                fullWidth,
                                                Icon
}) => (<LoadingButton
  startIcon={Icon}
  disabled={disabled}
  onClick={onClick}
  loading={isSubmitting}
  type={type}
  variant={variant}
  color={color}
  size={size}
  fullWidth={fullWidth}
  sx={{ ...(label === undefined ?  {
      minWidth:'auto',
      '.MuiButton-startIcon':{
        margin: '0'
      }
    } :{}) }}
>{label}</LoadingButton>);

export default BtnLoading;
