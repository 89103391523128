import React, { FunctionComponent } from 'react';
import { useField } from "formik";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';

interface OwnProps{
  placeholder?: string;
  name: string,
  label? : string
  required? : boolean,
  transparent?: boolean

}


type Props = OwnProps;

const MyNumberInput: FunctionComponent<Props> = ({ name, placeholder, transparent, label,...other }) => {
  const [field, meta, helper] = useField(name)


  const handleChange = (number: any)=>{
    let numb = parseInt(number);
    if(numb != null && !isNaN(numb)){
      helper.setValue(number )
    }else{
      helper.setValue(undefined)
    }
  }
  return (

      <FormControl fullWidth error={meta.touched && !!meta.error} sx={{ width: '100%', flex: 1 }}>
        <TextField
          variant={transparent ? "filled": 'outlined'}
          margin="normal"
          id={name}
          style={{ background: '#fff', marginTop:5 }}
          label={label}
          value={meta.value != undefined ? meta.value : ""}
          type="number"
          sx={{
            ...(transparent && {
              '.MuiInputBase-input':{ background: "#fff", }
            })
        }}
          onChange={(event)=>handleChange(event.currentTarget.value)}
          {...other}/>

      {meta.touched && meta.error ? (
        <Alert severity="error">{meta.error}</Alert>
      ): null}
    </FormControl>
  )
};

export default MyNumberInput;
