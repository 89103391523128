import React, { FunctionComponent, ReactNode } from "react";
import { styled, SxProps, Theme } from "@mui/material/styles";

interface OwnProps {
  children: ReactNode
  noPadding?: boolean
  sx?: SxProps<Theme>
}

type Props = OwnProps;

const Wrapper = styled('div',
  {
    shouldForwardProp: (prop) => prop !== "noPadding" && prop !== "sx"
  })<OwnProps>(({ noPadding, theme }) => ({

  [theme.breakpoints.down("md")]:{
    display: 'block'
  },
  display: 'flex',
  width: '100%',
  flex: '0 1 100%',
  maxWidth: '100%',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  ...(!noPadding ? {
    padding: '0 10px',
  }: {}),

  marginBottom: '20px'
}));

const TopToolBar: FunctionComponent<Props> = ({ children, noPadding, sx }) => (
    <Wrapper noPadding={noPadding} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {children}
    </Wrapper>

  );

export default TopToolBar;
