import { DragEvent, useState } from 'react';
import {Grid} from "@mui/material";

interface Props{
    handleResult : (file: File[]) =>void
}
export function FileDrop({handleResult}: Props) {
    const [isOver, setIsOver] = useState(false);

    // Define the event handlers
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        handleResult(droppedFiles);
    };

    return (
        <>
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
                width: '100%',
                border: '1px dotted',

                backgroundColor: isOver ? 'lightblue' : '#eee',
            }}
        >
            Drag and drop some images here
        </div>

        </>
    );
}
