import React, {FunctionComponent, useContext, useEffect} from 'react';
import LoadingScreen from "../components/LoadingScreen";
import {AuthContext} from "../context/AuthContext";
import {Auth0ContextType} from "../@types/auth";
import {useNavigate} from "react-router-dom";

interface OwnProps {}

type Props = OwnProps;

const CorrectRoute: FunctionComponent<Props> = (props) => {
    const {user, isAuthenticated} = useContext(AuthContext) as Auth0ContextType;
    const navigate  = useNavigate();
    useEffect(() => {
        console.log(user);
        if(isAuthenticated){
            if(user?.role == "admin"){
                navigate('/admin/dashboard')
            }else{
                navigate('/account')
            }
        }

    }, [isAuthenticated]);

  return <LoadingScreen />;
};

export default CorrectRoute;
